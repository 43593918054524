import { ChangeDetectionStrategy, Component, computed, inject, OnInit, signal } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '@wndr/common/core/services/user.service';
import { assertNonNullWithReturn } from '@wndr/common/core/utils/assert-non-null';
import { NotificationService } from '@wndr/common/core/services/notification.service';
import { catchError, map, repeat, startWith, Subject, take, timer } from 'rxjs';
import { otpCodeMask } from '@wndr/common/core/utils/masks';
import { AsyncPipe } from '@angular/common';
import { catchValidationData } from '@wndr/common/core/utils/rxjs/catch-validation-error';
import { toggleExecutionState } from '@wndr/common/core/utils/rxjs/toggle-execution-state';

import { InputComponent } from '../controls/input/input.component';
import { AbstractModalComponent } from '../abstract-modal-component/abstract-modal-component';
import { ButtonDirective } from '../buttons/button.directive';
import { ModalWrapperComponent } from '../modal-wrapper/modal-wrapper.component';
import { InputErrorWrapperComponent } from '../error-wrappers/input-error-wrapper/input-error-wrapper.component';
import { FormErrorWrapperComponent } from '../error-wrappers/form-error-wrapper/form-error-wrapper.component';
import { TimePipe } from '../../pipes/time.pipe';
import { LoadingDirective } from '../../directives/loading.directive';

/** Confirm email dialog id. */
export const CONFIRM_EMAIL_DIALOG_ID = 'confirm-email-dialog';

/** Role to dismiss modal. */
export const CAN_DISMISS_CONFIRM_EMAIL_MODAL_ROLE = 'close-after-submission-role';

/** Interval between resending confirmation code. */
const RESEND_INTERVAL_MINUTES = 3;

/** Confirm email dialog data. */
export type ConfirmEmailDialogData = Readonly<{

	/** Whether user tries to confirm secondary email or not. */
	isSecondaryEmail: boolean;
}>;

/** Dialog component to confirm email address. */
@Component({
	selector: 'wndrc-confirm-email-dialog',
	standalone: true,
	imports: [
		TimePipe,
		AsyncPipe,
		InputComponent,
		ReactiveFormsModule,
		ButtonDirective,
		ModalWrapperComponent,
		InputErrorWrapperComponent,
		FormErrorWrapperComponent,
		LoadingDirective,
	],
	templateUrl: './confirm-email-dialog.component.html',
	styleUrl: './confirm-email-dialog.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmEmailDialogComponent
	extends AbstractModalComponent<ConfirmEmailDialogData, boolean>
	implements OnInit {
	private readonly userService = inject(UserService);

	private readonly notificationService = inject(NotificationService);

	private readonly timerInitiator$ = new Subject<void>();

	private readonly fb = inject(NonNullableFormBuilder);

	/** Whether process is running or not. */
	protected readonly isLoading = signal(false);

	/** Form element ID. */
	protected readonly formId = 'confirm-email-form';

	/** Whether user tries to confirm secondary email or not. */
	protected readonly isSecondaryEmail = computed(() => this.modalData().isSecondaryEmail);

	/** @inheritdoc */
	protected override readonly id = CONFIRM_EMAIL_DIALOG_ID;

	/** Timer. */
	protected readonly timer$ = timer(0, 1000).pipe(
		map(seconds => (RESEND_INTERVAL_MINUTES * 60) - (seconds + 1)),
		take(RESEND_INTERVAL_MINUTES * 60),
		repeat({ delay: () => this.timerInitiator$ }),
	);

	/** Whether time is completed. */
	protected readonly isTimerCompleted$ = this.timer$.pipe(
		map(seconds => seconds === 0),
		startWith(false),
	);

	/** Confirmation code mask. */
	protected readonly confirmationCodeMask = otpCodeMask;

	/** Control for code input. */
	protected readonly confirmationForm = this.fb.group({
		code: this.fb.control('', [
			Validators.required,
			Validators.minLength(6),
			Validators.maxLength(6),
		]),
	});

	/** @inheritdoc */
	public ngOnInit(): void {
		// Emulate a click to avoid code duplication outside the component (code request)
		// and request code when opening the dialog.
		this.onResendButtonClick();
	}

	/** Handle 'ngSubmit' of the confirmation form. */
	protected onSubmit(): void {
		this.confirmationForm.markAllAsTouched();
		if (this.confirmationForm.invalid) {
			return;
		}

		const { code } = this.confirmationForm.value;

		this.userService.confirmEmail(assertNonNullWithReturn(code), this.isSecondaryEmail()).pipe(
			take(1),
			toggleExecutionState(this.isLoading),
			catchValidationData(this.confirmationForm),
		)
			.subscribe({
				next: () => this.close({ data: true, role: CAN_DISMISS_CONFIRM_EMAIL_MODAL_ROLE }),
			});
	}

	/** On resend button click. */
	protected onResendButtonClick(): void {
		this.userService.sendConfirmationCode(this.isSecondaryEmail()).pipe(
			take(1),
			catchError((error: unknown) => {
				this.notificationService.notify('warning', 'Something went wrong. Please try again');
				throw error;
			}),
		)
			.subscribe({
				next: () => {
					this.notificationService.notify('success', 'Code has been sent to your email');
					this.timerInitiator$.next();
				},
			});
	}
}
